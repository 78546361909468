import { useState, useEffect, useCallback } from "react";
import SettingsDB from "../../Settingsdb";

const db = new SettingsDB();

function useGetSettings() {
  const [refresh, setRefresh] = useState(new Date().getTime());

  const getSettings = useCallback(async () => {
    const list = await db.getSettings();
    setSettings(list);
  }, []);

  const [settings, setSettings] = useState();
  useEffect(() => {
    getSettings();
  }, [refresh, getSettings]);

  const updateFn = async (data) => {
    return await db.updateSettings(data);
  };

  return {
    settings,
    updateFn,
    setRefresh,
  };
}
export default useGetSettings;
