import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      "Back to prescriptions": "Back to prescriptions",
      Save: "Save",
      Delete: "Delete",
      Edit: "Edit",
      Print: "Print",
      PDF: "PDF",
      Patient: "Patient",
      Address: "Address",
      Age: "Age",
      Gender: "Gender",
      "Date Issued": "Date Issued",
      "Type your prescription here ...": "Type your prescription here ...",
      "Follow up on": "Follow up on",
      "Prescription Options": "Prescription Options",
      Name: "Name",
      "Follow Up": "Follow Up",
      Date: "Date",
      "Practice details": "Practice details",
      "e.g. Michael Roberts, MD": "e.g. Michael Roberts, MD",
      "e.g. 344 City Heights New York, NY 10023":
        "e.g. 344 City Heights New York, NY 10023",
      Logo: "Logo",
      "Signature and license details": "Signature and license details",
      "Name above signature (optional)": "Name above signature (optional)",
      "Name of license": "Name of license",
      "your license number here": "your license number here",
      "Add License": "Add License",
      "Clinic schedules": "Clinic schedules",
      "Add Clinic": "Add Clinic",
      "Clinic Name": "Clinic Name",
      "Clinic Details": "Clinic Details",
      Prescriptions: "Prescriptions",
      Settings: "Settings",
      Language: "Language",
      Saved: "Saved",
      "Deleted Prescription": "Deleted Prescription",
      "Delete prescription": "Delete prescription",
      "Edit prescription": "Edit prescription",
      "Edit Image": "Edit Image",
      Cancel: "Cancel",
      Ok: "Ok",
      "Delete Prescription?": "Delete Prescription?",
      "Are you sure you want to delete this prescription?":
        "Are you sure you want to delete this prescription?",
      Search: "Search",
      "Choose File": "Choose File",
      "No file chosen": "No file chosen",
      to: "to",
      rows: "rows",
      "Rows per page:": "Rows per page:",
      "First Page": "First Page",
      "Previous Page": "Previous Page",
      "Next Page": "Next Page",
      "Last Page": "Last Page",
      Clear: "Clear",
      "Online Prescription Maker": "Online Prescription Maker",
      "Create a simple prescription using our powerful forms. Start by modifying the settings of your prescription, then start creating simple prescriptions. More layouts coming soon!":
        "Create a simple prescription using our powerful forms. Start by modifying the settings of your prescription, then start creating simple prescriptions. More layouts coming soon!",
      "Modify your settings": "Modify your settings",
      "Create a prescription": "Create a prescription",
      "Copyright © ": "Copyright © ",
      "Doctor's signature": "Doctor's signature",
      "Print Settings": "Print Settings",
      "Show Age": "Show Age",
      "Show Gender": "Show Gender",
      "Contact Us": "Contact Us",
      "Your prescriptions are saved in your browser. They will be erased if you clear your browser's cache.":
        "Your prescriptions are saved in your browser. They will be erased if you clear your browser's cache.",
      "Edit Labels": "Edit Labels",
      "Show Follow Up": "Show Follow Up",
      "Buy me a coffee": "Buy me a coffee",
      "We use cookies to ensure you get the best experience on our website. By continuing, you are agreeing to our use of cookies.":
        "We use cookies to ensure you get the best experience on our website. By continuing, you are agreeing to our use of cookies.",
      "I Agree": "I Agree",
      "Privacy Policy": "Privacy Policy",
    },
  },
  fr: {
    translation: {
      "Back to prescriptions": "Retour aux prescriptions",
      Save: "Sauver",
      Delete: "Supprimer",
      Edit: "Éditer",
      Print: "Impression",
      PDF: "PDF",
      Patient: "Patient",
      Address: "Adresse",
      Age: "Âge",
      Gender: "Le sexe",
      "Date Issued": "Date d'émission",
      "Type your prescription here ...": "Tapez votre prescription ici ...",
      "Follow up on": "Suivi de",
      "Prescription Options": "Options de prescription",
      Name: "Nom",
      "Follow Up": "Suivre",
      Date: "Date",
      "Practice details": "Détails pratiques",
      "e.g. Michael Roberts, MD": "par exemple Michael Roberts, MD",
      "e.g. 344 City Heights New York, NY 10023":
        "par exemple 344 City Heights New York, NY 10023",
      Logo: "Logo",
      "Signature and license details":
        "Détails de la signature et de la licence",
      "Name above signature (optional)":
        "Nom au-dessus de la signature (facultatif)",
      "Name of license": "Nom de la licence",
      "your license number here": "votre numéro de licence ici",
      "Add License": "Ajouter une licence",
      "Clinic schedules": "Horaires des cliniques",
      "Add Clinic": "Ajouter une clinique",
      "Clinic Name": "Nom de la clinique",
      "Clinic Details": "Détails de la clinique",
      Prescriptions: "Les ordonnances",
      Settings: "Paramètres",
      Language: "Langue",
      Saved: "Enregistré",
      "Deleted Prescription": "Ordonnance supprim,",
      "Delete prescription": "Supprimer la prescription",
      "Edit prescription": "Modifier la prescription",
      "Edit Image": "Éditer l'image",
      Cancel: "Annuler",
      Ok: "D'accord",
      "Delete Prescription?": "Supprimer la prescription",
      "Are you sure you want to delete this prescription?":
        "Voulez-vous vraiment supprimer cette prescription?",
      Search: "Chercher",
      "Choose File": "Choisir le fichier",
      "No file chosen": "Aucun fichier choisi",
      to: "à",
      rows: "Lignes",
      "Rows per page:": "Lignes par page:",
      "First Page": "Première page",
      "Previous Page": "Page précédente",
      "Next Page": "Page suivante",
      "Last Page": "Dernière page",
      Clear: "Clair",
      "Online Prescription Maker": "Fabricant de prescription en ligne",
      "Create a simple prescription using our powerful forms. Start by modifying the settings of your prescription, then start creating simple prescriptions. More layouts coming soon!":
        "Créez une prescription simple en utilisant nos puissants formulaires. Commencez par modifier les paramètres de votre prescription, puis commencez à créer des prescriptions simples. Plus de mises en page à venir!",
      "Modify your settings": "Modifiez vos paramètres",
      "Create a prescription": "Créer une prescription",
      "Copyright © ": "Copyright © ",
      "Doctor's signature": "Signature du médecin",
      "Print Settings": "Paramètres d'impression",
      "Show Age": "Afficher l'âge",
      "Show Gender": "Afficher le sexe",
      "Contact Us": "Nous contacter",
      "Your prescriptions are saved in your browser. They will be erased if you clear your browser's cache.":
        "Vos prescriptions sont enregistrées dans votre navigateur. Ils seront effacés si vous effacez le cache de votre navigateur.",
      "Edit Labels": "Modifier les étiquettes",
      "Show Follow Up": "Afficher le suivi",
      "Buy me a coffee": "Achetez-moi un café",
      "We use cookies to ensure you get the best experience on our website. By continuing, you are agreeing to our use of cookies.":
        "Nous utilisons des cookies pour vous garantir la meilleure expérience sur notre site Web. En continuant, vous acceptez notre utilisation des cookies.",
      "I Agree": "Je suis d'accord",
      "Privacy Policy": "politique de confidentialité",
    },
  },
  zh: {
    translation: {
      "Back to prescriptions": "返回处方",
      Save: "保存",
      Delete: "删除",
      Edit: "编辑",
      Print: "打印",
      PDF: "PDF格式",
      Patient: "患者",
      Address: "地址",
      Age: "年龄",
      Gender: "性别",
      "Date Issued": "发行日期",
      "Type your prescription here ...": "在这里输入您的处方...",
      "Follow up on": "跟进",
      "Prescription Options": "处方选项",
      Name: "名称",
      "Follow Up": "跟进",
      Date: "日期",
      "Practice details": "实践细节",
      "e.g. Michael Roberts, MD": "例如迈克尔·罗伯茨医学博士",
      "e.g. 344 City Heights New York, NY 10023": "例如344纽约市高地NY 10023",
      Logo: "商标",
      "Signature and license details": "签名和许可证详细信息",
      "Name above signature (optional)": "签名上方的姓名（可选）",
      "Name of license": "牌照名称",
      "your license number here": "您的牌照号码在这里",
      "Add License": "添加许可证",
      "Clinic schedules": "临床时间表",
      "Add Clinic": "新增诊所",
      "Clinic Name": "诊所名称",
      "Clinic Details": "诊所详情",
      Prescriptions: "处方药",
      Settings: "设定值",
      Language: "语言",
      Saved: "已保存",
      "Deleted Prescription": "删除处方",
      "Delete prescription": "删除处方",
      "Edit prescription": "编辑处方",
      "Edit Image": "编辑影像",
      Cancel: "取消",
      Ok: "好",
      "Delete Prescription?": "删除处方？",
      "Are you sure you want to delete this prescription?":
        "您确定要删除此处方吗？",
      Search: "搜索",
      "Choose File": "选择文件",
      "No file chosen": "没有选中任何文件",
      to: "至",
      rows: "行数",
      "Rows per page:": "每页行数：",
      "First Page": "第一页",
      "Previous Page": "上一页",
      "Next Page": "下一页",
      "Last Page": "最后一页",
      Clear: "明确",
      "Online Prescription Maker": "在线处方机",
      "Create a simple prescription using our powerful forms. Start by modifying the settings of your prescription, then start creating simple prescriptions. More layouts coming soon!":
        "使用我们强大的表格创建简单的处方。首先修改您的处方设置，然后开始创建简单的处方。更多布局即将推出！",
      "Modify your settings": "修改设置",
      "Create a prescription": "创建处方",
      "Copyright © ": "版权所有© ",
      "Doctor's signature": "医生签名",
      "Print Settings": "列印设定",
      "Show Age": "显示年龄",
      "Show Gender": "显示性别",
      "Contact Us": "联系我们",
      "Your prescriptions are saved in your browser. They will be erased if you clear your browser's cache.":
        "您的处方保存在浏览器中。 如果您清除浏览器的缓存，它们将被删除。",
      "Edit Labels": "编辑标签",
      "Show Follow Up": "显示跟进",
      "Buy me a coffee": "给我买杯咖啡",
      "We use cookies to ensure you get the best experience on our website. By continuing, you are agreeing to our use of cookies.":
        "我们使用cookie来确保您在我们的网站上获得最佳体验。 点击继续，即表示您同意我们使用cookie。",
      "I Agree": "我同意",
      "Privacy Policy": "隐私政策",
    },
  },
  ru: {
    translation: {
      "Back to prescriptions": "Вернуться к рецептам",
      Save: "Сохранить",
      Delete: "Удалить",
      Edit: "Редактировать",
      Print: "Распечатать",
      PDF: "PDF",
      Patient: "Терпеливый",
      Address: "Адрес",
      Age: "Возраст",
      Gender: "Пол",
      "Date Issued": "Дата выпуска",
      "Type your prescription here ...": "Введите свой рецепт здесь ...",
      "Follow up on": "Следить за",
      "Prescription Options": "Варианты рецепта",
      Name: "Название",
      "Follow Up": "Следовать за",
      Date: "Дата",
      "Practice details": "Детали практики",
      "e.g. Michael Roberts, MD": "например Майкл Робертс MD",
      "e.g. 344 City Heights New York, NY 10023":
        "например 344 Сити Хайтс Нью-Йорк Нью-Йорк 10023",
      Logo: "Логотип",
      "Signature and license details": "Подпись и детали лицензии",
      "Name above signature (optional)": "Имя над подписью (необязательно)",
      "Name of license": "Наименование лицензии",
      "your license number here": "Ваш номер лицензии здесь",
      "Add License": "Добавить лицензию",
      "Clinic schedules": "Расписание клиники",
      "Add Clinic": "Добавить клинику",
      "Clinic Name": "Название клиники",
      "Clinic Details": "Детали клиники",
      Prescriptions: "Предписания",
      Settings: "Настройки",
      Language: "Язык",
      Saved: "Сохраненный",
      "Deleted Prescription": "Удаленный рецепт",
      "Delete prescription": "Удалить рецепт",
      "Edit prescription": "Изменить рецепт",
      "Edit Image": "Редактировать изображение",
      Cancel: "Отмена",
      Ok: "Хорошо",
      "Delete Prescription?": "Удалить рецепт?",
      "Are you sure you want to delete this prescription?":
        "Вы уверены, что хотите удалить этот рецепт?",
      Search: "Поиск",
      "Choose File": "Выберите файл",
      "No file chosen": "Файл не выбран",
      to: "в",
      rows: "строки",
      "Rows per page:": "Строк на страницу:",
      "First Page": "Первая страница",
      "Previous Page": "Предыдущая страница",
      "Next Page": "Следующая Страница",
      "Last Page": "Предыдущая страница",
      Clear: "Очистить",
      "Online Prescription Maker": "Интернет рецепт Maker",
      "Create a simple prescription using our powerful forms. Start by modifying the settings of your prescription, then start creating simple prescriptions. More layouts coming soon!":
        "Создайте простой рецепт, используя наши мощные формы. Начните с изменения настроек вашего рецепта, затем начните создавать простые рецепты. Скоро появятся новые макеты!",
      "Modify your settings": "Изменить ваши настройки",
      "Create a prescription": "Создать рецепт",
      "Copyright © ": "Авторское право © ",
      "Doctor's signature": "Подпись доктора",
      "Print Settings": "Настройки печати",
      "Show Age": "Показать возраст",
      "Show Gender": "Показать пол",
      "Contact Us": "Связаться с нами",
      "Your prescriptions are saved in your browser. They will be erased if you clear your browser's cache.":
        "Ваши рецепты сохраняются в вашем браузере. Они будут удалены, если вы очистите кеш вашего браузера.",
      "Edit Labels": "Изменить ярлыки",
      "Show Follow Up": "Показать продолжение",
      "Buy me a coffee": "Купи мне кофе",
      "We use cookies to ensure you get the best experience on our website. By continuing, you are agreeing to our use of cookies.":
        "Мы используем куки-файлы, чтобы обеспечить вам максимальный комфорт на нашем сайте. Продолжая, вы соглашаетесь с нашим использованием куки.",
      "I Agree": "Я согласен",
      "Privacy Policy": "политика конфиденциальности",
    },
  },
  pt: {
    translation: {
      "Back to prescriptions": "Voltar às prescrições",
      Save: "Salve ",
      Delete: "Excluir",
      Edit: "Editar",
      Print: "Impressão",
      PDF: "PDF",
      Patient: "Paciente",
      Address: "Endereço",
      Age: "Era",
      Gender: "Gênero",
      "Date Issued": "Data de Emissão",
      "Type your prescription here ...": "Digite sua receita aqui ...",
      "Follow up on": "Acompanhar",
      "Prescription Options": "Opções de prescrição",
      Name: "Nome",
      "Follow Up": "Acompanhamento",
      Date: "Encontro",
      "Practice details": "Detalhes da prática",
      "e.g. Michael Roberts, MD": "por exemplo, Michael Roberts, MD",
      "e.g. 344 City Heights New York, NY 10023":
        "por exemplo, 344 City Heights New York, NY 10023",
      Logo: "Logótipo",
      "Signature and license details": "Detalhes da assinatura e licença",
      "Name above signature (optional)": "Nome acima da assinatura (opcional)",
      "Name of license": "Nome da licença",
      "your license number here": "seu número de licença aqui",
      "Add License": "Adicionar licença",
      "Clinic schedules": "Horários da clínica",
      "Add Clinic": "Adicionar clínica",
      "Clinic Name": "Nome da clínica",
      "Clinic Details": "Detalhes da clínica",
      Prescriptions: "Prescrições",
      Settings: "Configurações",
      Language: "Língua",
      Saved: "Salvou",
      "Deleted Prescription": "Prescrição excluída",
      "Delete prescription": "Excluir prescrição",
      "Edit prescription": "Editar prescrição",
      "Edit Image": "Editar imagem",
      Cancel: "Cancelar",
      Ok: "Está bem",
      "Delete Prescription?": "Excluir receita?",
      "Are you sure you want to delete this prescription?":
        "Tem certeza de que deseja excluir esta prescrição?",
      Search: "Procurar",
      "Choose File": "Escolher arquivo",
      "No file chosen": "Nenhum arquivo selecionado",
      to: "para",
      rows: "linhas",
      "Rows per page:": "Linhas por página:",
      "First Page": "Primeira página",
      "Previous Page": "Página anterior",
      "Next Page": "Próxima página",
      "Last Page": "Última página",
      Clear: "clarear",
      "Online Prescription Maker": "Criador de Prescrição Online",
      "Create a simple prescription using our powerful forms. Start by modifying the settings of your prescription, then start creating simple prescriptions. More layouts coming soon!":
        "Crie uma receita simples usando nossos formulários poderosos. Comece modificando as configurações da sua receita e comece a criar receitas simples. Mais layouts em breve!",
      "Modify your settings": "Modifique suas configurações",
      "Create a prescription": "Crie uma receita",
      "Copyright © ": "Copyright © ",
      "Doctor's signature": "Assinatura do médico",
      "Print Settings": "Configurações de impressão",
      "Show Age": "Mostrar idade",
      "Show Gender": "Mostrar sexo",
      "Contact Us": "Contate-Nos",
      "Your prescriptions are saved in your browser. They will be erased if you clear your browser's cache.":
        "Suas prescrições são salvas no seu navegador. Eles serão apagados se você limpar o cache do seu navegador.",
      "Edit Labels": "Editar marcadores",
      "Show Follow Up": "Mostrar acompanhamento",
      "Buy me a coffee": "Me compre um café",
      "We use cookies to ensure you get the best experience on our website. By continuing, you are agreeing to our use of cookies.":
        "Utilizamos cookies para garantir que você obtenha a melhor experiência em nosso site. Ao continuar, você concorda com o uso de cookies.",
      "I Agree": "Concordo",
      "Privacy Policy": "política de Privacidade",
    },
  },
  es: {
    translation: {
      "Back to prescriptions": "Volver a las recetas",
      Save: "Salvar",
      Delete: "Eliminar",
      Edit: "Editar",
      Print: "Impresión",
      PDF: "PDF",
      Patient: "Paciente",
      Address: "Habla a",
      Age: "Años",
      Gender: "Género",
      "Date Issued": "Fecha de emisión",
      "Type your prescription here ...": "Escriba su receta aquí ...",
      "Follow up on": "Darle seguimiento a",
      "Prescription Options": "Opciones de prescripción",
      Name: "Nombre",
      "Follow Up": "Seguimiento",
      Date: "Fecha",
      "Practice details": "Detalles de la práctica",
      "e.g. Michael Roberts, MD": "p.ej. Dr. Michael Roberts",
      "e.g. 344 City Heights New York, NY 10023":
        "p.ej. 344 City Heights Nueva York NY 10023",
      Logo: "Logo",
      "Signature and license details": "Firma y detalles de la licencia",
      "Name above signature (optional)": "Nombre sobre la firma (opcional)",
      "Name of license": "Nombre de la licencia",
      "your license number here": "su número de licencia aquí",
      "Add License": "Agregar licencia",
      "Clinic schedules": "Horarios de la clínica",
      "Add Clinic": "Agregar clínica",
      "Clinic Name": "Nombre de la clínica",
      "Clinic Details": "Detalles de la clínica",
      Prescriptions: "Prescripciones",
      Settings: "Configuraciones",
      Language: "Idioma",
      Saved: "Salvado",
      "Deleted Prescription": "Prescripción eliminada",
      "Delete prescription": "Eliminar receta",
      "Edit prescription": "Editar receta",
      "Edit Image": "Editar imagen",
      Cancel: "Cancelar",
      Ok: "Okay",
      "Delete Prescription?": "¿Eliminar receta?",
      "Are you sure you want to delete this prescription?":
        "¿Está seguro de que desea eliminar esta receta?",
      Search: "Buscar",
      "Choose File": "Elija el archivo",
      "No file chosen": "Ningún archivo elegido",
      to: "a",
      rows: "filas",
      "Rows per page:": "Filas por página:",
      "First Page": "Primera página",
      "Previous Page": "Pagina anterior",
      "Next Page": "Siguiente página",
      "Last Page": "Última página",
      Clear: "despejar",
      "Online Prescription Maker": "Creador de recetas en línea",
      "Create a simple prescription using our powerful forms. Start by modifying the settings of your prescription, then start creating simple prescriptions. More layouts coming soon!":
        "Cree una receta simple usando nuestras poderosas formas. Comience modificando la configuración de su receta y luego comience a crear recetas simples. ¡Más diseños próximamente!",
      "Modify your settings": "Modifique su configuración",
      "Create a prescription": "Crea una receta",
      "Copyright © ": "Copyright © ",
      "Doctor's signature": "Firma del doctor",
      "Print Settings": "Configuraciones de impresión",
      "Show Age": "Mostrar edad",
      "Show Gender": "Mostrar género",
      "Contact Us": "Contáctenos",
      "Your prescriptions are saved in your browser. They will be erased if you clear your browser's cache.":
        "Sus recetas se guardan en su navegador. Se borrarán si borra la memoria caché de su navegador.",
      "Edit Labels": "Editar etiquetas",
      "Show Follow Up": "Mostrar seguimiento",
      "Buy me a coffee": "Cómprame un café",
      "We use cookies to ensure you get the best experience on our website. By continuing, you are agreeing to our use of cookies.":
        "Utilizamos cookies para garantizar que obtenga la mejor experiencia en nuestro sitio web. Al continuar, acepta nuestro uso de cookies.",
      "I Agree": "Estoy de acuerdo",
      "Privacy Policy": "política de privacidad",
    },
  },
  nl: {
    translation: {
      "Back to prescriptions": "Terug naar recepten",
      Save: "Sparen",
      Delete: "Verwijderen",
      Edit: "Bewerk",
      Print: "Afdrukken",
      PDF: "PDF",
      Patient: "Geduldig",
      Address: "Adres",
      Age: "Leeftijd",
      Gender: "Geslacht",
      "Date Issued": "Uitgiftedatum",
      "Type your prescription here ...": "Typ hier uw recept ...",
      "Follow up on": "Vervolg op",
      "Prescription Options": "Receptopties",
      Name: "Naam",
      "Follow Up": "Opvolgen",
      Date: "Datum",
      "Practice details": "Oefen details",
      "e.g. Michael Roberts, MD": "b.v. Michael Roberts MD",
      "e.g. 344 City Heights New York, NY 10023":
        "b.v. 344 City Heights New York NY 10023",
      Logo: "Logo",
      "Signature and license details": "Handtekening- en licentiegegevens",
      "Name above signature (optional)": "Naam boven handtekening (optioneel)",
      "Name of license": "Naam van licentie",
      "your license number here": "uw licentienummer hier",
      "Add License": "Licentie toevoegen",
      "Clinic schedules": "Kliniekschema's",
      "Add Clinic": "Clinic toevoegen",
      "Clinic Name": "Naam kliniek",
      "Clinic Details": "Kliniekdetails",
      Prescriptions: "Voorschriften",
      Settings: "Instellingen",
      Language: "Taal",
      Saved: "Opgeslagen",
      "Deleted Prescription": "Recept verwijderd",
      "Delete prescription": "Voorschrift verwijderen",
      "Edit prescription": "Recept bewerken",
      "Edit Image": "Bewerk afbeelding",
      Cancel: "Annuleer",
      Ok: "OK",
      "Delete Prescription?": "Recept verwijderen?",
      "Are you sure you want to delete this prescription?":
        "Weet u zeker dat u dit recept wilt verwijderen?",
      Search: "Zoeken",
      "Choose File": "Kies bestand",
      "No file chosen": "Geen bestand gekozen",
      to: "naar",
      rows: "rijen",
      "Rows per page:": "Rijen per pagina:",
      "First Page": "Eerste pagina",
      "Previous Page": "Vorige pagina",
      "Next Page": "Volgende bladzijde",
      "Last Page": "Laatste pagina",
      Clear: "ontruimen",
      "Online Prescription Maker": "Online Receptmaker",
      "Create a simple prescription using our powerful forms. Start by modifying the settings of your prescription, then start creating simple prescriptions. More layouts coming soon!":
        "Maak een eenvoudig recept met behulp van onze krachtige formulieren. Wijzig eerst de instellingen van uw recept en begin met het maken van eenvoudige recepten. Binnenkort meer lay-outs!",
      "Modify your settings": "Pas uw instellingen aan",
      "Create a prescription": "Maak een recept",
      "Copyright © ": "Copyright © ",
      "Doctor's signature": "Handtekening van de dokter",
      "Print Settings": "Afdrukinstellingen",
      "Show Age": "Toon leeftijd",
      "Show Gender": "Geslacht weergeven",
      "Contact Us": "Neem contact op",
      "Your prescriptions are saved in your browser. They will be erased if you clear your browser's cache.":
        "Uw recepten worden opgeslagen in uw browser. Ze worden gewist als u de cache van uw browser wist.",
      "Edit Labels": "Labels bewerken",
      "Show Follow Up": "Laat vervolg zien",
      "Buy me a coffee": "Koop me een kop koffie",
      "We use cookies to ensure you get the best experience on our website. By continuing, you are agreeing to our use of cookies.":
        "We gebruiken cookies om ervoor te zorgen dat u de beste ervaring op onze website krijgt. Door verder te gaan, gaat u akkoord met ons gebruik van cookies.",
      "I Agree": "Ik ga akkoord",
      "Privacy Policy": "Privacybeleid",
    },
  },
  ja: {
    translation: {
      "Back to prescriptions": "処方箋に戻る",
      Save: "保存する",
      Delete: "削除する",
      Edit: "編集する",
      Print: "印刷する",
      PDF: "PDF",
      Patient: "患者",
      Address: "住所",
      Age: "年齢",
      Gender: "性別",
      "Date Issued": "日付発行",
      "Type your prescription here ...": "ここに処方箋を入力してください...",
      "Follow up on": "フォローアップ",
      "Prescription Options": "処方オプション",
      Name: "名前",
      "Follow Up": "ファローアップ",
      Date: "日付",
      "Practice details": "演習の詳細",
      "e.g. Michael Roberts, MD": "例えばマイケルロバーツMD",
      "e.g. 344 City Heights New York, NY 10023":
        "例えば344シティハイツニューヨークNY 10023",
      Logo: "ロゴ",
      "Signature and license details": "署名とライセンスの詳細",
      "Name above signature (optional)": "署名の上の名前（オプション）",
      "Name of license": "ライセンス名",
      "your license number here": "ここにあなたのライセンス番号",
      "Add License": "ライセンスを追加",
      "Clinic schedules": "クリニックのスケジュール",
      "Add Clinic": "クリニックを追加",
      "Clinic Name": "クリニック名",
      "Clinic Details": "クリニックの詳細",
      Prescriptions: "処方せん",
      Settings: "設定",
      Language: "言語",
      Saved: "保存しました",
      "Deleted Prescription": "処方箋を削除しました",
      "Delete prescription": "処方箋を削除",
      "Edit prescription": "処方箋を編集",
      "Edit Image": "画像を編集",
      Cancel: "キャンセル",
      Ok: "OK",
      "Delete Prescription?": "処方箋を削除しますか？",
      "Are you sure you want to delete this prescription?":
        "この処方箋を削除してもよろしいですか？",
      Search: "探す",
      "Choose File": "ファイルを選ぶ",
      "No file chosen": "ファイルが選択されていません",
      to: "に",
      rows: "行",
      "Rows per page:": "ページあたりの行数：",
      "First Page": "一ページ目",
      "Previous Page": "前のページ",
      "Next Page": "次のページ",
      "Last Page": "最後のページ",
      Clear: "澄む",
      "Online Prescription Maker": "オンライン処方箋メーカー",
      "Create a simple prescription using our powerful forms. Start by modifying the settings of your prescription, then start creating simple prescriptions. More layouts coming soon!":
        "強力なフォームを使用して簡単な処方箋を作成します。処方箋の設定を変更することから始め、次に単純な処方箋の作成を開始します。より多くのレイアウトがすぐに来ます！",
      "Modify your settings": "設定を変更する",
      "Create a prescription": "処方箋を作成する",
      "Copyright © ": "著作権© ",
      "Doctor's signature": "医師の署名",
      "Print Settings": "印刷設定",
      "Show Age": "年齢を表示",
      "Show Gender": "性別を表示",
      "Contact Us": "お問い合わせ",
      "Your prescriptions are saved in your browser. They will be erased if you clear your browser's cache.":
        "処方箋はブラウザに保存されます。 ブラウザのキャッシュをクリアすると、それらは消去されます。",
      "Edit Labels": "ラベルを編集",
      "Show Follow Up": "フォローアップを表示",
      "Buy me a coffee": "コーヒーを買って",
      "We use cookies to ensure you get the best experience on our website. By continuing, you are agreeing to our use of cookies.":
        "私たちはあなたが私たちのウェブサイトで最高の体験を得られるようにクッキーを使用しています。 続けることで、あなたはクッキーの私達の使用に同意しています。",
      "I Agree": "同意する",
      "Privacy Policy": "プライバシーポリシー",
    },
  },
  de: {
    translation: {
      "Back to prescriptions": "Zurück zu den Rezepten",
      Save: "Sparen",
      Delete: "Löschen",
      Edit: "Bearbeiten",
      Print: "Drucken",
      PDF: "PDF",
      Patient: "Geduldig",
      Address: "Adresse",
      Age: "Alter",
      Gender: "Geschlecht",
      "Date Issued": "Ausgabedatum",
      "Type your prescription here ...": "Geben Sie hier Ihr Rezept ein ...",
      "Follow up on": "Follow-up auf",
      "Prescription Options": "Verschreibungsoptionen",
      Name: "Name",
      "Follow Up": "Nachverfolgen",
      Date: "Datum",
      "Practice details": "Übungsdetails",
      "e.g. Michael Roberts, MD": " z.B. Michael Roberts, MD",
      "e.g. 344 City Heights New York, NY 10023":
        "z.B. 344 City Heights New York, NY 10023",
      Logo: "Logo",
      "Signature and license details": "Unterschrift und Lizenzdetails",
      "Name above signature (optional)": "Name über Unterschrift (optional)",
      "Name of license": "Name der Lizenz",
      "your license number here": "Ihre Lizenznummer hier",
      "Add License": "Lizenz hinzufügen",
      "Clinic schedules": "Klinikpläne",
      "Add Clinic": "Klinik hinzufügen",
      "Clinic Name": "Name der Klinik",
      "Clinic Details": "Klinikdetails",
      Prescriptions: "Rezepte",
      Settings: "Die Einstellungen",
      Language: "Sprache",
      Saved: "Gerettet",
      "Deleted Prescription": "Rezept gelöscht",
      "Delete prescription": "Rezept löschen",
      "Edit prescription": "Rezept bearbeiten",
      "Edit Image": "Bild bearbeiten",
      Cancel: "Stornieren",
      Ok: "OK",
      "Delete Prescription?": "Rezept löschen?",
      "Are you sure you want to delete this prescription?":
        "Möchten Sie dieses Rezept wirklich löschen?",
      Search: "Suche",
      "Choose File": "Datei wählen",
      "No file chosen": "Keine Datei ausgewählt",
      to: "zu",
      rows: "Reihen",
      "Rows per page:": "Zeilen pro Seite:",
      "First Page": "Erste Seite",
      "Previous Page": "Vorherige Seite",
      "Next Page": "Nächste Seite",
      "Last Page": "Letzte Seite",
      Clear: "Löschen",
      "Online Prescription Maker": "Online Prescription Maker",
      "Create a simple prescription using our powerful forms. Start by modifying the settings of your prescription, then start creating simple prescriptions. More layouts coming soon!":
        " Erstellen Sie ein einfaches Rezept mit unseren leistungsstarken Formularen. Ändern Sie zunächst die Einstellungen Ihres Rezepts und erstellen Sie dann einfache Rezepte. Weitere Layouts folgen in Kürze!",
      "Modify your settings": "Ändern Sie Ihre Einstellungen",
      "Create a prescription": "Erstellen Sie ein Rezept",
      "Copyright © ": "Copyright © ",
      "Doctor's signature": "Unterschrift des Arztes",
      "Print Settings": "Druckeinstellungen",
      "Show Age": "Alter zeigen",
      "Show Gender": "Geschlecht anzeigen",
      "Contact Us": "Kontaktiere uns",
      "Your prescriptions are saved in your browser. They will be erased if you clear your browser's cache.":
        "Ihre Rezepte werden in Ihrem Browser gespeichert. Sie werden gelöscht, wenn Sie den Cache Ihres Browsers leeren.",
      "Edit Labels": "Beschriftungen bearbeiten",
      "Show Follow Up": "Zeigen Folge",
      "Buy me a coffee": "Kauf mir einen Kaffee",
      "We use cookies to ensure you get the best experience on our website. By continuing, you are agreeing to our use of cookies.":
        "Wir verwenden Cookies, um sicherzustellen, dass Sie die beste Erfahrung auf unserer Website erhalten. Wenn Sie fortfahren, stimmen Sie der Verwendung von Cookies zu.",
      "I Agree": "Genau",
      "Privacy Policy": "Datenschutzrichtlinie",
    },
  },
  it: {
    translation: {
      "Back to prescriptions": "Torna alle prescrizioni",
      Save: "Salva",
      Delete: "Elimina",
      Edit: "Modificare",
      Print: "Stampa",
      PDF: "PDF",
      Patient: "Paziente",
      Address: "Indirizzo",
      Age: "Età",
      Gender: "Genere",
      "Date Issued": "Data emesso",
      "Type your prescription here ...": "Scrivi qui la tua prescrizione ...",
      "Follow up on": "Segui su",
      "Prescription Options": "Opzioni di prescrizione",
      Name: "Nome",
      "Follow Up": "Azione supplementare",
      Date: "Data",
      "Practice details": "Dettagli pratica",
      "e.g. Michael Roberts, MD": "per esempio. Michael Roberts MD",
      "e.g. 344 City Heights New York, NY 10023":
        "per esempio. 344 City Heights New York NY 10023",
      Logo: "Logo",
      "Signature and license details": "Firma e dettagli della licenza",
      "Name above signature (optional)": "Nome sopra la firma (opzionale)",
      "Name of license": "Nome della licenza",
      "your license number here": "il tuo numero di licenza qui",
      "Add License": "Aggiungi licenza",
      "Clinic schedules": "Orari della clinica",
      "Add Clinic": "Aggiungi clinica",
      "Clinic Name": "Nome clinica",
      "Clinic Details": "Dettagli della clinic",
      Prescriptions: "Prescrizioni",
      Settings: "Impostazioni",
      Language: "Linguaggio",
      Saved: "Salvato",
      "Deleted Prescription": "Prescription eliminata",
      "Delete prescription": "Elimina la prescrizione",
      "Edit prescription": "Modifica prescrizione",
      "Edit Image": "Modifica immagine",
      Cancel: "Annulla",
      Ok: "Ok",
      "Delete Prescription?": "Elimina la prescrizione?",
      "Are you sure you want to delete this prescription?":
        "Sei sicuro di voler eliminare questa prescrizione?",
      Search: "Ricerca",
      "Choose File": "Scegli il file",
      "No file chosen": "Nessun file scelto",
      to: "per",
      rows: "righe",
      "Rows per page:": "Righe per pagina:",
      "First Page": "Prima pagina",
      "Previous Page": "Pagina precedente",
      "Next Page": "Pagina successiva",
      "Last Page": "Ultima pagina",
      Clear: "Sgombrare",
      "Online Prescription Maker": "Creatore di prescrizioni online",
      "Create a simple prescription using our powerful forms. Start by modifying the settings of your prescription, then start creating simple prescriptions. More layouts coming soon!":
        "Crea una semplice prescrizione usando i nostri potenti moduli. Inizia modificando le impostazioni della tua prescrizione, quindi inizia a creare semplici prescrizioni. Altri layout in arrivo!",
      "Modify your settings": "Modifica le tue impostazioni",
      "Create a prescription": "Crea una prescrizione",
      "Copyright © ": "Copyright ©",
      "Doctor's signature": "Firma del dottore",
      "Print Settings": "Impostazioni di stampa",
      "Show Age": "Mostra l'età",
      "Show Gender": "Mostra genere",
      "Contact Us": "Contattaci",
      "Your prescriptions are saved in your browser. They will be erased if you clear your browser's cache.":
        "Le tue prescrizioni vengono salvate nel tuo browser. Saranno cancellati se si cancella la cache del browser.",
      "Edit Labels": "Modifica etichette",
      "Show Follow Up": "Mostra follow-up",
      "Buy me a coffee": "Comprami un caffè",
      "We use cookies to ensure you get the best experience on our website. By continuing, you are agreeing to our use of cookies.":
        "Utilizziamo i cookie per assicurarti la migliore esperienza sul nostro sito Web. Continuando, accetti il nostro utilizzo dei cookie.",
      "I Agree": "Sono d'accordo",
      "Privacy Policy": "politica sulla riservatezza",
    },
  },
  ex: {
    translation: {
      "Back to prescriptions": "",
      Save: "",
      Delete: "",
      Edit: "",
      Print: "",
      PDF: "",
      Patient: "",
      Address: "",
      Age: "",
      Gender: "",
      "Date Issued": "",
      "Type your prescription here ...": "",
      "Follow up on": "",
      "Prescription Options": "",
      Name: "",
      "Follow Up": "",
      Date: "",
      "Practice details": "",
      "e.g. Michael Roberts, MD": "",
      "e.g. 344 City Heights New York, NY 10023": "",
      Logo: "",
      "Signature and license details": "",
      "Name above signature (optional)": "",
      "Name of license": "",
      "your license number here": "",
      "Add License": "",
      "Clinic schedules": "",
      "Add Clinic": "",
      "Clinic Name": "",
      "Clinic Details": "",
      Prescriptions: "",
      Settings: "",
      Language: "",
      Saved: "",
      "Deleted Prescription": "",
      "Delete prescription": "",
      "Edit prescription": "",
      "Edit Image": "",
      Cancel: "",
      Ok: "",
      "Delete Prescription?": "",
      "Are you sure you want to delete this prescription?": "",
      Search: "",
      "Choose File": "",
      "No file chosen": "",
      to: "",
      rows: "",
      "Rows per page:": "",
      "First Page": "",
      "Previous Page": "",
      "Next Page": "",
      "Last Page": "",
      Clear: "",
      "Online Prescription Maker": "",
      "Create a simple prescription using our powerful forms. Start by modifying the settings of your prescription, then start creating simple prescriptions. More layouts coming soon!":
        "",
      "Modify your settings": "",
      "Create a prescription": "",
      "Copyright © ": "",
      "Doctor's signature": "",
      "Print Settings": "",
      "Show Age": "",
      "Show Gender": "",
      "Contact Us": "",
      "Your prescriptions are saved in your browser. They will be erased if you clear your browser's cache.":
        "",
      "Edit Labels": "",
      "Show Follow Up": "",
      "Buy me a coffee": "",
      "We use cookies to ensure you get the best experience on our website. By continuing, you are agreeing to our use of cookies.":
        "",
      "I Agree": "",
      "Privacy Policy": "",
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
