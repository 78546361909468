import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { navigate } from "@reach/router";
import React from "react";
import { useTranslation } from "react-i18next";
// import BottomAd from "./components/Ads/BottomAd";
// import CookieConsent from "react-cookie-consent";

function Copyright() {
  const { t } = useTranslation();
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {t("Copyright © ")}
      <Link color="inherit" href=".">
        {t("Online Prescription Maker")}
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

function handleSettingsClick() {
  navigate("/settings");
  localStorage.setItem("cookieConsent", "true");
}

function handlePrescriptionClick() {
  navigate("/prescriptions");
  localStorage.setItem("cookieConsent", "true");
}

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

export default function Home() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box
      className={classes.heroContent}
      flex={1}
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      {/* Hero unit */}
      <Container maxWidth="sm">
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          {t("Online Prescription Maker")}
        </Typography>
        <Typography variant="h5" align="center" color="textSecondary" paragraph>
          {t(
            "Create a simple prescription using our powerful forms. Start by modifying the settings of your prescription, then start creating simple prescriptions. More layouts coming soon!"
          )}
        </Typography>
        <div className={classes.heroButtons}>
          <Grid container spacing={2} justify="center">
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                href="./settings"
                onClick={handleSettingsClick}
              >
                {t("Modify your settings")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                href="./prescriptions"
                onClick={handlePrescriptionClick}
              >
                {t("Create a prescription")}
              </Button>
            </Grid>
          </Grid>
        </div>
      </Container>
      {/* Footer */}
      <footer className={classes.footer}>
        <div className={classes.heroButtons}>
          <Grid container spacing={2} justify="center">
            <Grid item>
              <Link
                href="https://www.buymeacoffee.com/steppie"
                target="blank"
                color="inherit"
                rel="nofollow noopener noreferrer"
              >
                <Typography
                  variant="subtitle1"
                  align="center"
                  color="textSecondary"
                  component="p"
                >
                  {t("Buy me a coffee")}
                </Typography>
              </Link>
            </Grid>
            <Grid item>
              <Link
                href="https://docs.google.com/forms/d/e/1FAIpQLSe43TSCMVDR_SGqIByj9dm0suvLr3YxHl1hqfL3YRaUeOSxSg/viewform?usp=sf_link"
                target="blank"
                color="inherit"
                rel="nofollow noopener noreferrer"
              >
                <Typography
                  variant="subtitle1"
                  align="center"
                  color="textSecondary"
                  component="p"
                >
                  {t("Contact Us")}
                </Typography>
              </Link>
            </Grid>
            <Grid item>
              <Link href="./privacy-policy" color="inherit">
                <Typography
                  variant="subtitle1"
                  align="center"
                  color="textSecondary"
                  component="p"
                >
                  {t("Privacy Policy")}
                </Typography>
              </Link>
            </Grid>
          </Grid>
        </div>
        <Copyright />
      </footer>
      {/* End footer */}
      {/* <CookieConsent
        location="bottom"
        buttonText={t("I Agree")}
        cookieName="cookie"
        style={{ background: "#2B373B" }}
        buttonStyle={{
          background: "#3f50b5",
          color: "#ffffff",
          fontSize: "13px",
        }}
        expires={150}
        acceptOnScroll={true}
        onAccept={({ acceptedByScrolling }) => {
          localStorage.setItem("cookieConsent", "true");
        }}
      >
        {t(
          "We use cookies to ensure you get the best experience on our website. By continuing, you are agreeing to our use of cookies."
        )}{" "}
      </CookieConsent> */}
      {/* <BottomAd /> */}
    </Box>
  );
}
