import DateFnsUtils from "@date-io/date-fns";
import {
  Box,
  Container,
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Router } from "@reach/router";
import React from "react";
import "./App.css";
import HeaderBar from "./components/header/HeaderBar";
import { PrinterProvider } from "./components/hooks/usePrintPrescription";
import { LocaleProvider } from "./components/hooks/useLocale";
import SnackbarProvider from "./components/hooks/useSnackbar";
import PrescriptionEdit from "./components/prescription/PrescriptionEdit";
import PrescriptionList from "./components/prescription/PrescriptionList";
import PrescriptionView from "./components/prescription/PrescriptionView";
import Settings from "./components/Settings";
import Home from "./Home";
import PrivacyPolicy from "./components/PrivacyPolicy";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexFlow: "column",
    height: "100vh",
    padding: 0,
    margin: 0,
  },
  body: {
    backgroundColor: grey[200],
    display: "flex",
    flex: 1,
  },
});

const theme = createMuiTheme({
  // palette: {
  //   primary: {
  //     main: grey[50],
  //   },
  //   secondary: deepPurple,
  // },
  status: {
    danger: "orange",
  },
});

function App() {
  const classes = useStyles();
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          <PrinterProvider>
            <LocaleProvider>
              <Container maxWidth={false} className={classes.root}>
                <HeaderBar />
                <Box className={classes.body}>
                  <Router style={{ display: "flex", flex: 1 }}>
                    <Home path="/" />
                    <PrescriptionList path="prescriptions" />
                    <PrescriptionView path="prescriptions/:id" />
                    <PrescriptionEdit path="prescriptions/:id/edit" />
                    <Settings path="settings" />
                    <PrivacyPolicy path="privacy-policy" />
                  </Router>
                </Box>
              </Container>
            </LocaleProvider>
          </PrinterProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
}

export default App;
