import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Field, Form, Formik } from "formik";
import React from "react";
import { SimpleFileUpload } from "formik-material-ui";
import * as Yup from "yup";
import { Grid, DialogContent } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const validationSchema = Yup.object().shape({
  file: Yup.mixed()
    .strict()
    .notRequired()
    .test("fileType", "Unsupported File Format", (value) => {
      if (value && value.type) {
        return [
          "image/jpg",
          "image/jpeg",
          "image/gif",
          "image/png",
          "image/svg+xml",
        ].includes(value.type);
      } else if (value) {
        return value.includes("base64");
      } else {
        return true;
      }
    }),
});

export default function FormDialog(props) {
  const { open, onClose } = props;
  const { t } = useTranslation();

  function handleClose() {
    onClose();
  }

  const toBase64 = (file) => {
    if (typeof file === "string" && file.includes("base64")) {
      return file;
    }
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  async function onSubmit(values, actions) {
    if (values.file) {
      values.file = await toBase64(values.file);
    }
    onClose(values.file);
  }

  const initialValues = {
    file: "",
  };

  return (
    <div>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
        enableReinitialize={true}
        render={(formikProps) => {
          return (
            <Form>
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle id="form-dialog-title">
                  {t("Edit Image")}
                </DialogTitle>
                <DialogContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <Field
                        id="file"
                        component={SimpleFileUpload}
                        name="file"
                        onChange={formikProps.handleChange}
                        value={formikProps.values.file}
                        margin="dense"
                        accept=".png,.jpeg,.jpg,.gif"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </DialogContent>

                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    {t("Cancel")}
                  </Button>
                  <Button
                    onClick={() => formikProps.submitForm()}
                    color="primary"
                  >
                    {t("Ok")}
                  </Button>
                </DialogActions>
              </Dialog>
            </Form>
          );
        }}
      ></Formik>
    </div>
  );
}
