import { Box } from "@material-ui/core";
import Handlebars from "handlebars";
import React from "react";
import __html from "../../templates/PrescriptionTemplate";
import { normalizeHTMLValues } from "../../utils";
import useGetPrescription from "../hooks/useGetPrescription";
import useGetSettings from "../hooks/useGetSettings";
import { usePrintPrescription } from "../hooks/usePrintPrescription";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import RxLogo from "../../assets/rx";

const template = Handlebars.compile(__html);

function Prescription(props) {
  const { id } = props;
  const { t } = useTranslation();

  const { prescription } = useGetPrescription(id);
  const { settings } = useGetSettings();

  // Format the line breaks to <br/>
  const p1 = get(prescription, "prescription");
  if (p1) {
    prescription.prescription = p1.replace(/\n/g, "<br/>");
  }

  const data = normalizeHTMLValues({
    ...prescription,
    ...settings,
  });

  const [printRef] = usePrintPrescription();

  return (
    <Box>
      <div style={{ display: "none" }}>
        <div
          ref={printRef}
          dangerouslySetInnerHTML={{
            __html: template({
              ...data,
              rxLogo: RxLogo,
              patientLng: data.patientLng || t("Patient"),
              addressLng: data.addressLng || t("Address"),
              ageLng: data.ageLng || t("Age"),
              genderLng: data.genderLng || t("Gender"),
              dateLng: data.dateLng || t("Date"),
              followupLng: data.followupLng || t("Follow up on"),
              isPrinter: true,
            }),
          }}
        />
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: template({
            ...data,
            rxLogo: RxLogo,
            patientLng: data.patientLng || t("Patient"),
            addressLng: data.addressLng || t("Address"),
            ageLng: data.ageLng || t("Age"),
            genderLng: data.genderLng || t("Gender"),
            dateLng: data.dateLng || t("Date"),
            followupLng: data.followupLng || t("Follow up on"),
          }),
        }}
      />
    </Box>
  );
}

export default Prescription;
