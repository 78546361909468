import React, { useRef, useContext } from "react";

const PrinterContext = React.createContext({
  printRef: {
    current: null,
  },
});

export function PrinterProvider(props) {
  const printRef = useRef();

  return (
    <PrinterContext.Provider
      value={{
        printRef,
      }}
    >
      {props && props.children}
    </PrinterContext.Provider>
  );
}

export function usePrintPrescription() {
  // Returns a printRef object and function to set printRef
  const { printRef } = useContext(PrinterContext);
  return [printRef];
}
