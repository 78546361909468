import PouchDB from "pouchdb";

export default class SettingsDB {
  constructor() {
    this.db = new PouchDB("settings");
  }

  async getSettings() {
    let settings = {};

    let allData = await this.db.allDocs({ include_docs: true });

    if (allData.total_rows > 0) {
      settings = allData.rows[0].doc;
    }

    return settings;
  }

  async updateSettings(setting) {
    const response = await this.db.post(setting);
    return response;
  }
}
