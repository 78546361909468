import { format } from "date-fns";
import { get } from "lodash";
import { enGB as en, ru, it, pt, es, fr, de, nl, ja } from "date-fns/locale";
import zh from "date-fns/locale/zh-CN";

const locales = { en, ru, zh, it, pt, es, fr, de, nl, ja };

export function formatDate(date, formatStr = "PP") {
  if (!date) {
    return "";
  }
  window.__localeId__ = localStorage.getItem("lang") || "en";
  return format(new Date(date), formatStr, {
    locale: locales[window.__localeId__], // or global.__localeId__
  });
}

export function normalizeHTMLValues(templateValues) {
  const res = {};
  for (const key in templateValues) {
    const value = templateValues[key];
    switch (key) {
      case "date":
        res[key] = formatDate(value);
        break;
      case "followup":
        res[key] = formatDate(value);
        break;
      case "subheader":
        res[key] = value.replace(/(?:\r\n|\r|\n)/g, "<br>");
        break;
      case "clinics":
        res[key] = value.map(
          (val) =>
            (val = {
              clinicName: val.clinicName,
              details: get(val, "details", "").replace(
                /(?:\r\n|\r|\n)/g,
                "<br>"
              ),
            })
        );
        break;
      default:
        res[key] = value;
        break;
    }
  }
  return res;
}
