import React from "react";
import Body from "../Body";
import PrescriptionTable from "./PrescriptionTable";
import Warning from "../Warning";
import { Hidden } from "@material-ui/core";
import PrescriptionTableSm from "./PrescriptionTableSm";
import MetaTags from "react-meta-tags";

function Prescriptions() {
  return (
    <Body paperless>
      <MetaTags>
        {/* <meta name="robots" content="noindex, nofollow" /> */}
        <meta
          name="description"
          content="Online Prescription Maker's prescriptions page shows your created prescriptions. Add more, sort, select, edit, save and print!"
        />
      </MetaTags>
      <Hidden xsDown>
        <PrescriptionTable />
      </Hidden>
      <Hidden smUp>
        <PrescriptionTableSm />
      </Hidden>
      <Warning />
    </Body>
  );
}

export default Prescriptions;
