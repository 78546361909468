import { IconButton, makeStyles } from "@material-ui/core";
import { navigate } from "@reach/router";
import React from "react";
import Logo from "../../logo.png";

const useStyles = makeStyles((theme) => ({
  logo: {
    width: 40,
    height: 40,
  },
}));

function LogoIcon(props) {
  const { setValue } = props;
  const classes = useStyles();

  function handleHomeClick() {
    navigate(`/`);
    setValue(-1);
  }

  return (
    <IconButton aria-label="home" href="./" onClick={handleHomeClick}>
      <img alt="A prescription logo" src={Logo} className={classes.logo} />
    </IconButton>
  );
}

export default LogoIcon;
