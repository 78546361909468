import React, { useContext, useState } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SnackbarContext = React.createContext({
  showSnackbar: () => {},
  hideSnackbar: () => {},
});

export function useSnackBar() {
  const value = useContext(SnackbarContext);
  return {
    showSnackbar: value.showSnackbar,
    hideSnackbar: value.hideSnackbar,
  };
}

export default function SnackbarProvider(props) {
  const [state, setState] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  function showSnackbar(message = "", severity = "success") {
    setState({
      open: true,
      message,
      severity,
    });
  }

  function hideSnackbar() {
    setState({
      open: false,
      message: "",
    });
  }
  return (
    <SnackbarContext.Provider value={{ showSnackbar, hideSnackbar }}>
      <Snackbar
        open={state.open}
        autoHideDuration={4000}
        onClose={hideSnackbar}
      >
        <Alert onClose={hideSnackbar} severity={state.severity}>
          {state.message}
        </Alert>
      </Snackbar>
      {props && props.children}
    </SnackbarContext.Provider>
  );
}
