import { Box, Button, Grid, Link, makeStyles } from "@material-ui/core";
import { navigate } from "@reach/router";
import Handlebars from "handlebars";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ReactToPrint from "react-to-print";
import __html from "../../templates/PrescriptionTemplate";
import { normalizeHTMLValues } from "../../utils";
import useGetPrescription from "../hooks/useGetPrescription";
import useGetSettings from "../hooks/useGetSettings";
import { usePrintPrescription } from "../hooks/usePrintPrescription";
import { useSnackBar } from "../hooks/useSnackbar";
import ShowDeleteDialog from "../ShowDeleteDialog";

const template = Handlebars.compile(__html);

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(2),
  },
  button: {
    marginLeft: theme.spacing(1),
  },
}));

function PrescriptionButtonBar(props) {
  const classes = useStyles();
  const { id, mode, formikRef, setEditLabels, editLabels } = props;
  const { prescription, deleteFn } = useGetPrescription(id);
  const [printRef] = usePrintPrescription();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const { showSnackbar } = useSnackBar();
  const { t } = useTranslation();

  const { settings } = useGetSettings();

  const data = normalizeHTMLValues({ ...prescription, ...settings });

  function handleEditClick() {
    navigate(`/prescriptions/${id}/edit`);
  }
  function handleCancelClick() {
    navigate(`/prescriptions`);
  }
  function handlePreviewClick() {
    navigate(`/prescriptions/${id}`);
  }

  function handleEditLabelsClick() {
    setEditLabels(!editLabels);
  }

  function handleSavePrescriptionClick() {
    const formikProps = formikRef.current;
    if (formikProps) {
      formikProps.submitForm();
    }
  }
  async function handleDeleteClick() {
    setShowDeleteDialog(true);
  }

  async function downloadFile(response) {
    // const file = new Blob(response, { type: "application/pdf" });
    // //Build a URL from the file
    // const fileURL = URL.createObjectURL(file);
    // //Open the URL on new Window
    // window.open(fileURL);

    response.blob().then((blob) => {
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = url;
      a.download = prescription.patient + ".pdf";
      a.click();
    });
  }

  async function handleSaveClick() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "text/html" },
      body: template({
        ...data,
        isPrinter: true,
      }),
    };
    fetch(process.env.REACT_APP_API_ENDPOINT + "/print", requestOptions)
      .then((response) => downloadFile(response))
      .catch((e) => console.log(e));
  }

  async function handleCloseShowDeleteDialog(isDelete) {
    if (isDelete) {
      await deleteFn(id);
      navigate(`/prescriptions/`);
      showSnackbar(t("Deleted Prescription"));
    }
    setShowDeleteDialog(false);
  }

  // function handleNavigationChange(newValue) {
  //   setValue(newValue);
  //   if (newValue === 1) {
  //     handleEditClick();
  //   } else {
  //     handlePreviewClick();
  //   }
  // }

  // function getValue() {
  //   if (mode === "edit") {
  //     return 1;
  //   } else {
  //     return 0;
  //   }
  // }

  return (
    <div className={classes.root}>
      <Grid container justify="space-between" alignItems="flex-end">
        <Grid item>
          {mode === "preview" && (
            <Link
              component="button"
              variant="body2"
              onClick={handleCancelClick}
            >
              {t("Back to prescriptions")}
            </Link>
          )}
          {mode === "edit" && (
            <React.Fragment>
              <ActionButton onClick={handlePreviewClick}>
                {t("Cancel")}
              </ActionButton>
              <ActionButton onClick={handleEditLabelsClick}>
                {t("Edit Labels")}
              </ActionButton>
            </React.Fragment>
          )}
        </Grid>
        <Grid item>
          {mode === "edit" && (
            <Box display="flex" flexDirection="row">
              <ActionButton
                type="submit"
                onClick={handleSavePrescriptionClick}
                color="primary"
              >
                {t("Save")}
              </ActionButton>
              <ActionButton onClick={handleDeleteClick}>
                {t("Delete")}
              </ActionButton>
            </Box>
          )}
          {mode === "preview" && (
            <Box display="flex" flexDirection="row">
              <ActionButton onClick={handleEditClick}>{t("Edit")}</ActionButton>
              <ReactToPrint
                documentTitle={prescription.patient}
                trigger={() => <ActionButton>{t("Print")}</ActionButton>}
                content={() => printRef.current}
              />
              <ActionButton onClick={handleSaveClick}>{t("PDF")}</ActionButton>
            </Box>
          )}
        </Grid>
      </Grid>
      {showDeleteDialog && (
        <ShowDeleteDialog
          open={showDeleteDialog}
          onClose={handleCloseShowDeleteDialog}
        />
      )}
    </div>
  );
}

export default PrescriptionButtonBar;

function ActionButton(props) {
  const classes = useStyles();
  const { children, onClick, color, type } = props;
  return (
    <Button
      className={classes.button}
      size="small"
      variant="contained"
      color={color}
      disableElevation={true}
      onClick={onClick}
      type={type}
    >
      {children}
    </Button>
  );
}
