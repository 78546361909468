import {
  Box,
  Button,
  Container,
  Divider,
  Fab,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  Switch,
  Typography,
} from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ClearIcon from "@material-ui/icons/Clear";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import { Field, FieldArray, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import MetaTags from "react-meta-tags";
import Body from "./Body";
import useGetSettings from "./hooks/useGetSettings";
import { useSnackBar } from "./hooks/useSnackbar";
import ShowFileDialog from "./ShowFileDialog";

const useStyles = makeStyles((theme) => ({
  container: {
    marginLeft: theme.spacing(0),
  },
  padding: {
    paddingBottom: theme.spacing(0),
    paddingTop: theme.spacing(0),
  },
  fab: {
    margin: theme.spacing(1),
    zIndex: theme.zIndex.drawer + 1,
    position: "fixed",
    top: theme.spacing(5),
    right: theme.spacing(4),
  },
}));

function Settings() {
  const { t } = useTranslation();
  const classes = useStyles();

  const { settings: queriedData = {}, updateFn, setRefresh } = useGetSettings();
  const { showSnackbar } = useSnackBar();
  const [showFileDialog, setShowFileDialog] = useState();
  const [fileType, setFileType] = useState();

  let initialValues = {
    _id: "0",
    _rev: queriedData._rev || undefined,
    name: queriedData.name || "",
    signature: queriedData.signature,
    header: queriedData.header || "",
    subheader: queriedData.subheader || "",
    logo: queriedData.logo,
    showAge:
      queriedData.showAge !== false && queriedData.showAge !== true
        ? true
        : queriedData.showAge,
    showGender:
      queriedData.showGender !== false && queriedData.showGender !== true
        ? true
        : queriedData.showGender,
    showFollowUp:
      queriedData.showFollowUp !== false && queriedData.showFollowUp !== true
        ? true
        : queriedData.showFollowUp,
  };
  if (queriedData.clinics) {
    initialValues = { clinics: queriedData.clinics, ...initialValues };
  } else {
    initialValues = { clinics: [], ...initialValues };
  }

  if (queriedData.fields) {
    initialValues = { fields: queriedData.fields, ...initialValues };
  } else {
    initialValues = { fields: [], ...initialValues };
  }

  function handleEditSignature() {
    setShowFileDialog(true);
    setFileType("signature");
  }
  function handleEditLogo() {
    setShowFileDialog(true);
    setFileType("logo");
  }

  async function onSubmit(values, actions) {
    // do something with form values

    await updateFn(values);
    setRefresh(new Date().getTime());

    showSnackbar(t("Saved"));
  }

  return (
    <Body>
      <MetaTags>
        {/* <meta name="robots" content="noindex, nofollow" /> */}
        <meta
          name="description"
          content="Online Prescription Maker's settings page is used to modify your and add your practice details, signatures and licenses, clinics and more!"
        />
      </MetaTags>
      <Box display="flex" flexDirection="row" alignContent="start">
        <Container maxWidth="lg" className={classes.container}>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            enableReinitialize={true}
            render={(formikProps) => {
              function handleCloseShowFileDialog(base64) {
                if (base64) {
                  formikProps.setFieldValue(fileType, base64, false);
                }
                setShowFileDialog(false);
              }
              function handleAgeChange(event) {
                formikProps.setFieldValue("showAge", event.target.checked);
              }
              function handleGenderChange(event) {
                formikProps.setFieldValue("showGender", event.target.checked);
              }
              function handleFollowUpChange(event) {
                formikProps.setFieldValue("showFollowUp", event.target.checked);
              }
              return (
                <Form onSubmit={formikProps.handleSubmit}>
                  <Grid
                    container
                    alignItems="center"
                    className={classes.padding}
                  >
                    <Box pb={4}>
                      <Grid
                        container
                        alignItems="flex-start"
                        className={classes.padding}
                      >
                        <Grid item xs={12} md={6}>
                          <Grid
                            container
                            alignItems="center"
                            className={classes.padding}
                          >
                            <Grid item xs={12} md={12}>
                              <Typography variant="h6" htmlFor="header">
                                {t("Practice details")}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} md={11}>
                              <Field
                                component={TextField}
                                id="header"
                                name="header"
                                placeholder={t("e.g. Michael Roberts, MD")}
                                value={formikProps.values.header}
                                variant="outlined"
                                margin="dense"
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12} md={11}>
                              <Field
                                component={TextField}
                                id="subheader"
                                name="subheader"
                                value={formikProps.values.subheader}
                                placeholder={t(
                                  "e.g. 344 City Heights New York, NY 10023"
                                )}
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                multiline={true}
                                rows={3}
                              />
                            </Grid>
                            <Grid item xs={3} md={3}>
                              <Typography htmlFor="logo">
                                {t("Logo")}
                              </Typography>
                            </Grid>
                            {formikProps.values.logo && (
                              <Grid item xs={3} md={3}>
                                <img
                                  alt="Your selected logo"
                                  src={formikProps.values.logo}
                                  height="50vh"
                                />
                              </Grid>
                            )}
                            <Grid item xs={1} md={1}>
                              <IconButton
                                aria-label="edit logo"
                                onClick={handleEditLogo}
                              >
                                <EditIcon />
                              </IconButton>
                            </Grid>

                            {formikProps.values.logo && (
                              <Grid item xs={1} md={1}>
                                <IconButton
                                  aria-label="delete"
                                  onClick={() =>
                                    formikProps.setFieldValue("logo", "", false)
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Grid
                            container
                            alignItems="center"
                            className={classes.padding}
                          >
                            <Grid item xs={12} md={12}>
                              <Typography
                                variant="h6"
                                htmlFor="doctorInformation"
                              >
                                {t("Signature and license details")}
                              </Typography>
                            </Grid>

                            <Grid item xs={3} md={4}>
                              <Box p={1}>
                                <Typography htmlFor="signature">
                                  {t("Doctor's signature")}
                                </Typography>
                              </Box>
                            </Grid>
                            {formikProps.values.signature && (
                              <Grid item xs={3} md={4}>
                                <img
                                  src={formikProps.values.signature}
                                  alt="your selected signature"
                                  height="50vh"
                                />
                              </Grid>
                            )}
                            <Grid item xs={1} md={1}>
                              <IconButton
                                aria-label="edit signature"
                                onClick={handleEditSignature}
                              >
                                <EditIcon />
                              </IconButton>
                            </Grid>
                            {formikProps.values.signature && (
                              <Grid item xs={1} md={1}>
                                <IconButton
                                  aria-label="delete"
                                  onClick={() =>
                                    formikProps.setFieldValue(
                                      "signature",
                                      "",
                                      false
                                    )
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Grid>
                            )}

                            <Grid item xs={12} md={11}>
                              <Field
                                component={TextField}
                                name="name"
                                placeholder={t(
                                  "Name above signature (optional)"
                                )}
                                variant="outlined"
                                margin="dense"
                                fullWidth
                              />
                            </Grid>

                            <Grid item xs={12} md={11}>
                              <FieldArray
                                name="fields"
                                render={(arrayHelpers) => (
                                  <div>
                                    {formikProps.values.fields &&
                                      formikProps.values.fields.map(
                                        (friend, index) => (
                                          <div key={index}>
                                            <Grid
                                              container
                                              alignItems="center"
                                              spacing={1}
                                            >
                                              <Grid item xs={12} md={5}>
                                                <Field
                                                  component={TextField}
                                                  id={`fields['${index}'].label`}
                                                  name={`fields['${index}'].label`}
                                                  placeholder={t(
                                                    "Name of license"
                                                  )}
                                                  variant="outlined"
                                                  margin="dense"
                                                  fullWidth
                                                />
                                              </Grid>
                                              <Grid item xs={11} md={6}>
                                                <Field
                                                  component={TextField}
                                                  id={`fields['${index}'].value`}
                                                  name={`fields['${index}'].value`}
                                                  placeholder={t(
                                                    "your license number here"
                                                  )}
                                                  variant="outlined"
                                                  margin="dense"
                                                  fullWidth
                                                />
                                              </Grid>
                                              <Grid item xs={1} md={1}>
                                                <IconButton
                                                  aria-label="delete"
                                                  onClick={() =>
                                                    arrayHelpers.remove(index)
                                                  }
                                                >
                                                  <ClearIcon />
                                                </IconButton>
                                              </Grid>
                                            </Grid>
                                          </div>
                                        )
                                      )}

                                    {formikProps.values.fields.length < 5 && (
                                      <Grid item xs={12} md={12}>
                                        <Box pt={1}>
                                          <Button
                                            type="button"
                                            size="small"
                                            onClick={() =>
                                              arrayHelpers.push("")
                                            }
                                          >
                                            {t("Add License")}
                                          </Button>
                                        </Box>
                                      </Grid>
                                    )}
                                  </div>
                                )}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <FieldArray
                          name="clinics"
                          render={(arrayHelpers) => (
                            <Grid container>
                              <Grid item xs={12} md={12}>
                                <Grid container>
                                  <Grid item xs={6} md={12}>
                                    <Typography variant="h6" htmlFor="clinics">
                                      {t("Clinic schedules")}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={6} md={12}>
                                    {formikProps.values.clinics.length < 6 && (
                                      <Button
                                        color="primary"
                                        startIcon={<AddCircleOutlineIcon />}
                                        onClick={() =>
                                          arrayHelpers.push({
                                            clinicName: "",
                                            clinicDetails: "",
                                          })
                                        }
                                      >
                                        {t("Add Clinic")}
                                      </Button>
                                    )}
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid container spacing={2}>
                                {formikProps.values.clinics.map(
                                  (clinicObj, index) => (
                                    <Grid item xs={12} md={12} key={index}>
                                      <Grid
                                        container
                                        alignItems="center"
                                        className={classes.padding}
                                      >
                                        <div key={index}>
                                          <Divider />
                                          <Grid
                                            container
                                            alignItems="center"
                                            direction="row"
                                            justify="flex-start"
                                          >
                                            <Grid item xs={11} md={10}>
                                              <Field
                                                component={TextField}
                                                name={`clinics['${index}'].clinicName`}
                                                label={t("Clinic Name")}
                                                variant="outlined"
                                                margin="dense"
                                                fullWidth
                                              />
                                            </Grid>
                                            <Grid item xs={1} md={1}>
                                              <IconButton
                                                variant="contained"
                                                type="button"
                                                onClick={() =>
                                                  arrayHelpers.remove(index)
                                                }
                                              >
                                                <ClearIcon />
                                              </IconButton>
                                            </Grid>
                                            <Grid item xs={12} md={11}>
                                              <Field
                                                label={t("Clinic Details")}
                                                component={TextField}
                                                name={`clinics['${index}'].details`}
                                                multiline
                                                variant="outlined"
                                                margin="dense"
                                                fullWidth
                                                rows={3}
                                              />
                                            </Grid>
                                          </Grid>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  )
                                )}
                              </Grid>
                            </Grid>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Grid container>
                          <Grid item xs={12} md={12}>
                            <Typography variant="h6" htmlFor="header">
                              {t("Print Settings")}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <FormControlLabel
                              control={
                                <Field
                                  component={Switch}
                                  name="showAge"
                                  checked={formikProps.values.showAge}
                                  onChange={handleAgeChange}
                                />
                              }
                              label={t("Show Age")}
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <FormControlLabel
                              control={
                                <Field
                                  component={Switch}
                                  name="showGender"
                                  checked={formikProps.values.showGender}
                                  onChange={handleGenderChange}
                                />
                              }
                              label={t("Show Gender")}
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <FormControlLabel
                              control={
                                <Field
                                  component={Switch}
                                  name="showFollowUp"
                                  checked={formikProps.values.showFollowUp}
                                  onChange={handleFollowUpChange}
                                />
                              }
                              label={t("Show Follow Up")}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Fab
                      variant="extended"
                      color="secondary"
                      aria-label="create prescription"
                      className={classes.fab}
                      onClick={() => formikProps.submitForm()}
                    >
                      <SaveIcon />
                      Save
                    </Fab>
                  </Grid>
                  {showFileDialog && (
                    <ShowFileDialog
                      open={showFileDialog}
                      onClose={handleCloseShowFileDialog}
                    />
                  )}
                </Form>
              );
            }}
          />
        </Container>
      </Box>
    </Body>
  );
}

export default Settings;
