import { useEffect, useState, useCallback } from "react";
import PrescriptionDB from "../../Prescriptiondb";
const db = new PrescriptionDB();

function useGetPrescription(id) {
  const [prescriptionData, setPrescriptionData] = useState([]);
  const [refresh, setRefresh] = useState(new Date().getTime());
  const [prescriptionList, setPrescriptionList] = useState([]);

  const getPrescriptions = useCallback(async () => {
    const list = await db.getPrescriptions();
    setPrescriptionList(list);
  }, []);

  useEffect(
    function () {
      if (db && id) {
        const prescription = db.getPrescription(id).then(setPrescriptionData);
        setPrescriptionData(prescription);
      }
    },
    [refresh, id]
  );

  useEffect(
    function () {
      if (db) {
        getPrescriptions();
      }
    },
    [refresh, getPrescriptions]
  );

  const updateFn = async (data) => {
    return await db.updatePrescription(data);
  };

  const deleteFn = async (id) => {
    return await db.deletePrescription(id);
  };

  const createFn = async (id) => {
    return await db.createPrescription({
      _id: id,
      date: new Date(),
    });
  };

  return {
    prescription: prescriptionData,
    prescriptions: prescriptionList,
    createFn,
    updateFn,
    setRefresh,
    deleteFn,
  };
}

export default useGetPrescription;
