import { Box } from "@material-ui/core";
import { deepPurple, grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  body: {
    backgroundColor: grey[50],
    borderRadius: 8,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderTopStyle: "solid",
    borderTopWidth: "3px",
    borderTopColor: deepPurple[500],
    minHeight: "50vh",
    padding: theme.spacing(2),
  },
}));

function PrescriptionBody(props) {
  const classes = useStyles();
  const { children } = props;
  return (
    <Box boxShadow={2} className={classes.body}>
      {children}
    </Box>
  );
}

export default PrescriptionBody;
