import { Box, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    textAlign: "center",
  },
}));

export default function Warning() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <footer className={classes.footer}>
      <Box display="flex" flex={1} justifyContent="center">
        <Box maxWidth={400}>
          <Typography variant="body2" align="center" gutterBottom>
            {t(
              "Your prescriptions are saved in your browser. They will be erased if you clear your browser's cache."
            )}
          </Typography>
        </Box>
      </Box>
    </footer>
  );
}
