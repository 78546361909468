import React from "react";
import Body from "../Body";
import Prescription from "./Prescription";
import PrescriptionBody from "./PrescriptionBody";
import PrescriptionButtonBar from "./PrescriptionButtonBar";

function PrescriptionView(props) {
  const { id } = props;
  return (
    <Body rightComponent={<div />}>
      <PrescriptionButtonBar id={id} mode="preview" />
      <PrescriptionBody>
        <Prescription id={id}></Prescription>
      </PrescriptionBody>
    </Body>
  );
}

export default PrescriptionView;
