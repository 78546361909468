import { Box } from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import { navigate } from "@reach/router";
import MaterialTable from "material-table";
import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { normalizeHTMLValues } from "../../utils";
import useGetPrescription from "../hooks/useGetPrescription";
import { useSnackBar } from "../hooks/useSnackbar";
import ShowDeleteDialog from "../ShowDeleteDialog";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  fab: {
    margin: theme.spacing(1),
    zIndex: theme.zIndex.drawer + 1,
    position: "fixed",
    top: theme.spacing(5),
    right: theme.spacing(4),
  },
}));

export default function PrescriptionTable() {
  const { t } = useTranslation();
  const {
    prescriptions,
    createFn,
    deleteFn,
    setRefresh,
  } = useGetPrescription();
  const classes = useStyles();
  const [id, setId] = useState("");
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const { showSnackbar } = useSnackBar();

  const columns = [
    { title: t("Name"), field: "patient" },
    { title: t("Age"), field: "age" },
    { title: t("Date Issued"), field: "date" },
    { title: t("Follow Up"), field: "followup" },
  ];

  async function handleDeleteClick(event, rowData) {
    setId(rowData._id);
    setShowDeleteDialog(true);
  }

  function handleViewClick(event, rowData) {
    navigate(`/prescriptions/${rowData._id}`);
  }

  function handleEditClick(event, rowData) {
    navigate(`/prescriptions/${rowData._id}/edit`);
  }

  function handleCreateClick(event, rowData) {
    const id = uuidv4();
    createFn(id);
    navigate(`/prescriptions/${id}/edit`);
  }

  async function handleCloseShowDeleteDialog(isDelete) {
    if (isDelete) {
      await deleteFn(id);
      setRefresh(new Date().getTime());
      showSnackbar(t("Deleted Prescription"));
    }
    setShowDeleteDialog(false);
  }

  const data = prescriptions.map((prescription) =>
    normalizeHTMLValues({ ...prescription })
  );
  return (
    <Box>
      <Box>
        <Fab
          color="secondary"
          aria-label="create prescription"
          className={classes.fab}
          onClick={handleCreateClick}
        >
          <AddIcon />
        </Fab>
      </Box>
      <Box>
        <MaterialTable
          title={t("Prescriptions")}
          columns={columns}
          data={data}
          onRowClick={handleViewClick}
          localization={{
            pagination: {
              labelDisplayedRows: `{from}-{to} ${t("to")} {count}`,
              labelRowsSelect: t("rows"),
              labelRowsPerPage: t("Rows per page:"),
              firstAriaLabel: t("First Page"),
              firstTooltip: t("First Page"),
              previousAriaLabel: t("Previous Page"),
              previousTooltip: t("Previous Page"),
              nextAriaLabel: t("Next Page"),
              nextTooltip: t("Next Page"),
              lastAriaLabel: t("Last Page"),
              lastTooltip: t("Last Page"),
            },
            toolbar: {
              searchPlaceholder: t("Search"),
            },
            header: {
              actions: "", //  actions: 'Any text'
            },
          }}
          actions={[
            {
              icon: "delete",
              tooltip: t("Delete prescription"),
              onClick: handleDeleteClick,
            },
            {
              icon: "edit",
              tooltip: t("Edit prescription"),
              onClick: handleEditClick,
            },
          ]}
        />
        {showDeleteDialog && (
          <ShowDeleteDialog
            open={showDeleteDialog}
            onClose={handleCloseShowDeleteDialog}
          />
        )}
      </Box>
    </Box>
  );
}
