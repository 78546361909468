import React, { useContext, useState } from "react";

const LocaleContext = React.createContext({
  locale: "en",
});

export function LocaleProvider(props) {
  const [locale, setLocale] = useState("en");

  return (
    <LocaleContext.Provider
      value={{
        locale,
        setLocale,
      }}
    >
      {props && props.children}
    </LocaleContext.Provider>
  );
}

export function useLocale() {
  // Returns a locale object and function to set locale
  const { locale, setLocale } = useContext(LocaleContext);
  return { locale, setLocale };
}
