import PouchDB from "pouchdb";

export default class PrescriptionDB {
  constructor() {
    this.db = new PouchDB("prescriptions");
  }

  async getPrescriptions() {
    let prescriptions = [];
    let allData = await this.db.allDocs({ include_docs: true });

    allData.rows.forEach((n) => prescriptions.push(n.doc));

    return prescriptions;
  }

  async createPrescription(prescription) {
    const response = await this.db.post(prescription);
    return response;
  }

  async deletePrescription(_id) {
    const data = await this.db.get(_id);
    const response = await this.db.remove(_id, data._rev);
    return response;
  }

  async getPrescription(_id) {
    const data = await this.db.get(_id);
    return data;
  }

  async updatePrescription(prescription) {
    const response = await this.db.put(prescription);
    return response;
  }
}
