import { Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  grid: {
    display: "flex",
    margin: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
  },
}));

function Body(props) {
  const classes = useStyles();
  const { children, rightComponent, paperless } = props;

  function renderRightComponent() {
    if (!rightComponent) {
      return null;
    }
    return (
      <Grid item xs={11} sm={rightComponent ? 2 : 12}>
        {rightComponent}
      </Grid>
    );
  }

  return (
    <Grid container spacing={2} className={classes.grid}>
      <Grid item xs={12} sm={rightComponent ? 9 : 12}>
        {paperless ? (
          children
        ) : (
          <Paper className={classes.paper}>{children}</Paper>
        )}
      </Grid>
      {renderRightComponent()}
    </Grid>
  );
}

export default Body;
