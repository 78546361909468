import DateFnsUtils from "@date-io/date-fns";
import { Box, Grid, Hidden, Typography } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { navigate } from "@reach/router";
import { de, enGB as en, es, fr, it, ja, nl, pt, ru } from "date-fns/locale";
import zh from "date-fns/locale/zh-CN";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { DatePicker } from "formik-material-ui-pickers";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import Body from "../Body";
import useGetPrescription from "../hooks/useGetPrescription";
import { useLocale } from "../hooks/useLocale";
import { useSnackBar } from "../hooks/useSnackbar";
import PrescriptionBody from "./PrescriptionBody";
import PrescriptionButtonBar from "./PrescriptionButtonBar";

const locales = { en, ru, zh, it, pt, es, fr, de, nl, ja };

const validationSchema = Yup.object().shape({
  patient: Yup.string().required("Required"),
  age: Yup.number().positive(),
  prescription: Yup.string().required("Required"),
});

function PrescriptionEdit(props) {
  const [locale, setLocale] = useState("en");
  const [editLabels, setEditLabels] = useState(false);
  const { id } = props;
  const { t } = useTranslation();
  const { locale: lang } = useLocale();

  useEffect(() => {
    setLocale(lang);
  }, [lang]);

  const {
    prescription: queriedData,
    setRefresh,
    updateFn,
  } = useGetPrescription(id);

  const { showSnackbar } = useSnackBar();

  const formikRef = useRef();

  const localeFormatMap = {
    en: "d MMM yyyy",
    zh: "yyyy-dd-MM",
    ru: "d MMM yyyy",
    it: "d MMM yyyy",
    pt: "d MMM yyyy",
    es: "d MMM yyyy",
    fr: "d MMM yyyy",
    de: "d MMM yyyy",
    nl: "d MMM yyyy",
    ja: "yyyy/MM/dd",
  };

  const initialValues = {
    _id: id,
    patient: queriedData.patient || "",
    date: queriedData.date,
    address: queriedData.address || "",
    age: queriedData.age || "",
    sex: queriedData.sex || "",
    prescription: queriedData.prescription || "",
    followup: queriedData.followup || null,
    patientLng: queriedData.patientLng || t("Patient"),
    dateLng: queriedData.dateLng || t("Date Issued"),
    addressLng: queriedData.addressLng || t("Address"),
    ageLng: queriedData.ageLng || t("Age"),
    genderLng: queriedData.genderLng || t("Gender"),
    followupLng: queriedData.followupLng || t("Follow Up"),
  };

  async function handleSubmit(values, actions) {
    // do something with form values
    const toSaveData = { ...values, _rev: queriedData._rev };
    await updateFn(toSaveData);
    setRefresh(new Date().getTime());
    showSnackbar(t("Saved"));
    navigate(`/prescriptions/${id}`);
  }

  return (
    <Body rightComponent={<div />}>
      <PrescriptionButtonBar
        id={id}
        mode="edit"
        formikRef={formikRef}
        setEditLabels={setEditLabels}
        editLabels={editLabels}
      />
      <PrescriptionBody>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          enableReinitialize={true}
          render={(formikProps) => {
            return (
              <Form>
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  spacing={1}
                >
                  <Grid item xs={12} md={2}>
                    {!editLabels && (
                      <Typography>{formikProps.values.patientLng}</Typography>
                    )}
                    {editLabels && (
                      <Field
                        component={TextField}
                        id="patient"
                        name="patientLng"
                        placeholder={t("Patient")}
                        onChange={formikProps.handleChange}
                        value={formikProps.values.patientLng}
                        margin="dense"
                      />
                    )}{" "}
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <Field
                      component={TextField}
                      id="patient"
                      name="patient"
                      onChange={formikProps.handleChange}
                      value={formikProps.values.patient}
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    {!editLabels && (
                      <Typography>{formikProps.values.addressLng}</Typography>
                    )}
                    {editLabels && (
                      <Field
                        component={TextField}
                        id="patient"
                        name="addressLng"
                        placeholder={t("Address")}
                        onChange={formikProps.handleChange}
                        value={formikProps.values.addressLng}
                        margin="dense"
                      />
                    )}{" "}
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <Field
                      component={TextField}
                      id="address"
                      name="address"
                      onChange={formikProps.handleChange}
                      value={formikProps.values.address}
                      // variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <Grid
                      container
                      alignItems="center"
                      justify="center"
                      spacing={2}
                    >
                      {/* Age */}
                      <React.Fragment>
                        <Grid item xs={12} md={1}>
                          {!editLabels && (
                            <Typography>{formikProps.values.ageLng}</Typography>
                          )}
                          {editLabels && (
                            <Field
                              component={TextField}
                              id="ageLng"
                              name="ageLng"
                              placeholder={t("Age")}
                              onChange={formikProps.handleChange}
                              value={formikProps.values.ageLng}
                              margin="dense"
                            />
                          )}{" "}
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <Field
                            component={TextField}
                            id="age"
                            name="age"
                            type="number"
                            onChange={formikProps.handleChange}
                            value={formikProps.values.age}
                            margin="dense"
                          />
                        </Grid>
                      </React.Fragment>

                      {/* Gender */}
                      <React.Fragment>
                        <Grid item xs={12} md={2}>
                          {!editLabels && (
                            <Typography>
                              {formikProps.values.genderLng}
                            </Typography>
                          )}
                          {editLabels && (
                            <Field
                              component={TextField}
                              id="genderLng"
                              name="genderLng"
                              placeholder={t("Gender")}
                              onChange={formikProps.handleChange}
                              value={formikProps.values.genderLng}
                              margin="dense"
                            />
                          )}{" "}
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <Field
                            component={TextField}
                            id="sex"
                            name="sex"
                            onChange={formikProps.handleChange}
                            value={formikProps.values.sex}
                            margin="dense"
                          />
                        </Grid>
                      </React.Fragment>

                      {/* Date */}
                      <Grid item xs={12} md={2}>
                        {!editLabels && (
                          <Typography>{formikProps.values.dateLng}</Typography>
                        )}
                        {editLabels && (
                          <Field
                            component={TextField}
                            id="dateLng"
                            name="dateLng"
                            placeholder={t("Date Issued")}
                            onChange={formikProps.handleChange}
                            value={formikProps.values.dateLng}
                            margin="dense"
                          />
                        )}{" "}
                      </Grid>
                      <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={locales[locale]}
                      >
                        <Grid item xs={12} md={2}>
                          <Field
                            component={DatePicker}
                            name="date"
                            cancelLabel={t("Cancel")}
                            okLabel={t("Ok")}
                            format={localeFormatMap[locale]}
                            clearLabel={t("Clear")}
                            // inputVariant="outlined"
                            clearable={true}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Box p={2}>
                      <Field
                        component={TextField}
                        id="prescription"
                        name="prescription"
                        onChange={formikProps.handleChange}
                        value={formikProps.values.prescription}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        multiline={true}
                        rows={10}
                        placeholder={t("Type your prescription here ...")}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <FormLabel htmlFor="followup">
                      {!editLabels && (
                        <Typography>
                          {formikProps.values.followupLng}
                        </Typography>
                      )}
                      {editLabels && (
                        <Field
                          component={TextField}
                          id="followupLng"
                          name="followupLng"
                          placeholder={t("Follow Up")}
                          onChange={formikProps.handleChange}
                          value={formikProps.values.followupLng}
                          margin="dense"
                        />
                      )}{" "}
                    </FormLabel>
                  </Grid>
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={locales[locale]}
                  >
                    <Grid item xs={12} md={9}>
                      <Field
                        component={DatePicker}
                        cancelLabel={t("Cancel")}
                        okLabel={t("Ok")}
                        clearLabel={t("Clear")}
                        format={localeFormatMap[locale]}
                        name="followup"
                        clearable
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>
              </Form>
            );
          }}
        />
      </PrescriptionBody>
    </Body>
  );
}

export default PrescriptionEdit;

const FormLabel = (props) => {
  const { children, htmlFor } = props;
  return (
    <React.Fragment>
      <Hidden smDown>
        <Typography htmlFor={htmlFor} align="right" color="textSecondary">
          {children}
        </Typography>
      </Hidden>
      <Hidden mdUp>
        <Typography htmlFor={htmlFor} align="left" color="textSecondary">
          {children}
        </Typography>
      </Hidden>
    </React.Fragment>
  );
};
