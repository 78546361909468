import {
  AppBar,
  Box,
  Divider,
  FormControl,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  MenuItem,
  SwipeableDrawer,
  Tab,
  Tabs,
  Toolbar,
  Grid,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import Menu from "@material-ui/core/Menu";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MenuIcon from "@material-ui/icons/Menu";
import { navigate } from "@reach/router";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocale } from "../hooks/useLocale";
import LogoIcon from "./LogoIcon";

const useStyles = makeStyles((theme) => ({
  root: {},
  tabLabel: {
    textTransform: "capitalize",
    fontSize: "1.1em",
  },
  appBar: {
    borderBottomStyle: "solid",
    borderBottomWidth: "1px",
    borderBottomColor: grey[300],
  },
  drawer: { opacity: 0.99 },
  toolbar: {
    ...theme.mixins.toolbar,
    borderBottomStyle: "solid",
    borderBottomWidth: "1px",
    borderBottomColor: grey[300],
    backgroundColor: "#3f50b5",
  },
  language: {
    position: "relative",
    margin: theme.spacing(1),
    width: "100%",
    marginLeft: 0,
    color: grey[300],
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
    icon: {
      color: grey[300],
    },
  },
  list: {
    width: 250,
  },
}));

const languages = {
  en: "English",
  zh: "中文",
  it: "Italiano",
  ru: "Русский",
  pt: "Português",
  es: "Español",
  fr: "Français",
  de: "Deutsch",
  nl: "Nederlands",
  ja: "日本語",
};

function getISO(language) {
  let iso = "";
  for (var x in languages) {
    if (language === languages[x]) {
      iso = x;
    }
  }
  return iso;
}

function getIndex(iso) {
  let index = 0;
  for (var x in languages) {
    if (x === iso) {
      break;
    }
    index++;
  }
  return index;
}

let options = [];
for (var x in languages) {
  options.push(languages[x]);
}

const HeaderBar = (props) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const [value, setValue] = useState(-1);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [language, setLanguage] = useState("en");
  const { setLocale } = useLocale();

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElMenu, setAnchorElMenu] = useState(null);

  useEffect(() => {
    const lang = localStorage.getItem("lang");
    setLanguage(lang || "en");
  }, []);

  useEffect(() => {
    i18n.changeLanguage(language);
    setLocale(language);
  }, [language, i18n, setLocale]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    // eslint-disable-next-line default-case
    switch (newValue) {
      case 0:
        navigate("/prescriptions");
        localStorage.setItem("cookieConsent", "true");

        break;
      case 1:
        navigate("/settings");
        localStorage.setItem("cookieConsent", "true");

        break;
    }
  };

  const selectMenuPrescriptions = (event) => {
    setValue(0);
    navigate("/prescriptions");
    localStorage.setItem("cookieConsent", "true");

    setOpenDrawer(false);
  };

  const selectMenuSettings = (event) => {
    setValue(1);
    navigate("/settings");
    localStorage.setItem("cookieConsent", "true");

    setOpenDrawer(false);
  };

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickListItemMenu = (event) => {
    setAnchorElMenu(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    localStorage.setItem("lang", getISO(options[index]));
    setLanguage(getISO(options[index]));
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClickMenu = (event, index) => {
    localStorage.setItem("lang", getISO(options[index]));
    setLanguage(getISO(options[index]));
    setAnchorElMenu(null);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenDrawer(open);
  };

  const list = () => (
    <div className={classes.list} role="presentation">
      <List>
        <ListItem
          button
          onClick={selectMenuPrescriptions}
          selected={value === 0}
        >
          <ListItemText primary={t("Prescriptions")} />
        </ListItem>
        <ListItem button onClick={selectMenuSettings} selected={value === 1}>
          <ListItemText primary={t("Settings")} />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem
          button
          aria-haspopup="true"
          aria-controls="lock-menu"
          aria-label={t("Language")}
          onClick={handleClickListItemMenu}
        >
          <ListItemText primary={languages[language]} />
          <ListItemIcon>
            <ExpandMoreIcon className={classes.icon} />
          </ListItemIcon>
        </ListItem>
        <Menu
          id="lock-menu"
          anchorEl={anchorElMenu}
          keepMounted
          open={Boolean(anchorElMenu)}
          onClose={handleCloseMenu}
        >
          {options.map((option, index) => (
            <MenuItem
              key={option}
              selected={index === getIndex(language)}
              onClick={(event) => handleMenuItemClickMenu(event, index)}
            >
              {option}
            </MenuItem>
          ))}
        </Menu>
      </List>
    </div>
  );

  return (
    <div className={classes.root}>
      <AppBar elevation={0} className={classes.appBar} position="static">
        <Toolbar disableGutters={true}>
          <Box mr={6}>
            <LogoIcon setValue={setValue} />
          </Box>
          <Hidden xsDown>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
            >
              <Tab
                href="./prescriptions"
                label={t("Prescriptions")}
                className={classes.tabLabel}
              />
              <Tab
                href="./settings"
                label={t("Settings")}
                className={classes.tabLabel}
              />
            </Tabs>
            <Box display="flex" flex={1} justifyContent="flex-end">
              <FormControl className={classes.language}>
                <List component="nav" aria-label="Device settings">
                  <ListItem
                    button
                    aria-haspopup="true"
                    aria-controls="lock-menu"
                    aria-label={t("Language")}
                    onClick={handleClickListItem}
                  >
                    <ListItemText primary={languages[language]} />
                    <ListItemIcon style={{ color: grey[300] }}>
                      <ExpandMoreIcon className={classes.icon} />
                    </ListItemIcon>
                  </ListItem>
                </List>
                <Menu
                  id="lock-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === getIndex(language)}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </Menu>
              </FormControl>
            </Box>
          </Hidden>

          <Hidden smUp>
            <React.Fragment>
              <Box display="flex" flex={1} justifyContent="flex-end">
                <IconButton onClick={toggleDrawer(true)}>
                  <MenuIcon style={{ color: grey[200] }} />
                </IconButton>
                <SwipeableDrawer
                  anchor="right"
                  open={openDrawer}
                  onClose={toggleDrawer(false)}
                  onOpen={toggleDrawer(true)}
                  className={classes.drawer}
                >
                  <div className={classes.toolbar}>
                    <Grid
                      container
                      alignContent="center"
                      style={{ height: "100%" }}
                      ali
                    >
                      <IconButton onClick={toggleDrawer(false)}>
                        <MenuIcon style={{ color: grey[200] }} />
                      </IconButton>
                    </Grid>
                  </div>
                  {list()}
                </SwipeableDrawer>
              </Box>
            </React.Fragment>
          </Hidden>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default HeaderBar;
